:root {
  --app-bg-color: brown;
  --header-bg-color: #262524;
  --header-light-color: #2f2e2e;
  --font-color-light: bisque;
  --font-color-dark: #262524;
  --brand-color: #dba662;
  --brand-color-light: bisque;
  --outline-color: var(--brand-color-light);
}

body {
  color: var(--font-color-dark);
  background-color: var(--outline-color);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
}

.App-header {
  background-color: var(--header-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: var(--font-color-light);
  margin: 6px;
}
h1 {
  /*background-color: var(--header-light-color);*/  
  padding: 5px 50px 9px;
  margin-bottom: 7px;
}

.cat-form {
  padding: 12px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 820px;
  justify-content: center;
  background-color: var(--header-light-color);
}

.form-element {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form-element > input {
  margin: 5px;
  flex-grow: 1;
  padding: 5px 10px;
}

.form-element > label {
  margin-right: 5px;
}

.form-element > textarea {
  margin: 5px;
  flex-grow: 1;
  padding: 5px 10px;
}
.form-submit {
  background-color: var(--brand-color-light);
  color: var(--font-color-dark);
  font-weight: 500;
}

.cat-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
  margin: 6px;
  background-color: white;
}

.cat-card {
  min-width: 25vw;
  padding: 10px;
  border: 1px outset var(--outline-color);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cat-card--header {
  height: 18vh;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cat-card--text {
  max-width: 300px;
}

.likes {
  display: flex;  
  align-items: center;
  justify-content: center; 
  height: 9vh;
}
.likes > *:not(:last-child) {
  margin-right: 5px; 
  min-width: 30px;
} 

.like-button {
  border-radius: 18px;
  padding-top: 2px;
  height: 30px;
  width: 30px;
  background-color: var(--brand-color-light);
  border: none;
}
.like-button:hover {
  border: 1px solid var(--brand-color);
}
.like-button:active {
  border: 2px solid (var(--brand-color));
}

.no-cat {
  border: none;
  font-size: 16px;
  background-color: var(--brand-color-light);
  padding: 3px 8px;
}

.no-cat:hover {
  content: 🐈;
  /*border: 1px dashed var(--outline-color);*/
  box-shadow: 2px 3px 4px var(--outline-color);
}

.no-cat:hover::before {
  content: "🐕 ";
}
.no-cat::before {
  content: "🐈 ";
}

/* Utilities */
.flip {
  transform: scale(-1, 1);
}
.icon {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
